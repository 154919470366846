import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  
  private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public get loggedIn(): Observable<boolean> {
    return this._loggedIn.asObservable();
  }
  
  public authStateChanged = new Subject()

  constructor() { }

  // Update the user logged state
  setLoggedIn(loggedIn: boolean){
    this._loggedIn.next(loggedIn);
  }

  emitAuthStateChanged() {
    this.authStateChanged.next()
  }
}
