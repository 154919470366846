import {Component} from "@angular/core"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {Router} from "@angular/router"

@Component({
  selector: "app-ask-to-register-modal",
  templateUrl: "./ask-to-register-modal.component.html",
  styleUrls: ["./ask-to-register-modal.component.scss"],
})
export class AskToRegisterModalComponent {
  constructor(
    private router: Router,
    private modal: MatDialogRef<AskToRegisterModalComponent>
  ) {}

  signUp() {
    this.navigateTo("/auth/register")
    this.closeModal()
  }

  private navigateTo(url: string): void {
    this.router.navigate([url])
  }

  private closeModal() {
    this.modal.close()
  }
}
