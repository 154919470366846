import { isPlatformBrowser } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from "@angular/core";
import { AdobeDtbTracking } from "src/app/services/adobe_dtb_tracking.service";
import { SocialFooterItems } from "./footer.items";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  socialFooterItems = SocialFooterItems;
  isMobile = window.innerWidth < 1024;
  isFrench = this.locale === "fr";

  constructor(
    public adobeDtbTracking: AdobeDtbTracking,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  // Updates the parameter if the window size is changed
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.isMobile = event.target.innerWidth < 1024;
  }

  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    var interval = setInterval(() => {
      const elementToFind = document.getElementsByClassName(
        "evidon-consent-link"
      );

      if (elementToFind.length > 0) {
        elementToFind[0].addEventListener("click", (e) => e.preventDefault());
        window.clearInterval(interval);
      }
    }, 500);
  }
}
