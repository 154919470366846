import { isPlatformBrowser } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Auth } from "aws-amplify";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { environment } from "../environments/environment";
import { MTMPage, MTMPages } from "./components/desktop-toolbar/desktop-pages";
import { AskToRegisterModalComponent } from "./components/dialogs/ask-to-register-modal/ask-to-register-modal.component";
import { AccountService } from "./services/account/account.service";
import { DynamicScriptLoaderService } from "./services/dynamic-script-loader/dynamic-script-loader.service";
import { SeoService } from "./services/seo.service";

declare var digitalData: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  unsubscribeAll = new Subject();
  activeRoute: string;
  loadScript: Promise<any>;
  loadNewRelic = false;
  loadLoyaltyAmazonPixel = false;
  loadAwarenessAmazonPixel = false;
  loadPinterest = false;
  isMobile = window.innerWidth < 768;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(LOCALE_ID) private locale: string,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private accountService: AccountService,
    private seoService: SeoService,
    private title: Title,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: MatDialog
  ) {}

  // Displays a popup on browsers to add the website as a webapp
  @HostListener("window:beforeinstallprompt", ["$event"])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    if (e) {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      e.prompt(); // prompot user to add to homescreen
    }
  }

  async ngOnInit() {
    if (environment.dev) {
      this.loadRobotsInHeader();
    }

    await this.checkIfUserIsLoggedIn();
    this.activeRoute = this.router.url;
    this.watchRoute();

    setTimeout(() => this.askToRegister(), 3000);

    if (!isPlatformBrowser(this.platformId)) {
      let bases = document.getElementsByTagName("base");

      if (bases.length > 0) {
        bases[0].setAttribute("href", environment.baseHref);
      }
    }
  }

  // If on dev environment and no key, we redirect to the production website (security purpose)
  checkDevUrl() {
    if (this.route.snapshot.queryParamMap.get("s") !== environment.devUrlKey) {
      window.location.href = "https://www.mealsthatmatter.com";
    }
  }

  // Load all third-part scripts after page loaded
  async ngAfterViewInit() {
    if (environment.uat || environment.production) {
      await this.adobeImplementation();
      this.pinterestImplementation();
      this.facebookImplementation();
      this.newRelicImplementation();
      this.pixelImplementation();
      this.amazonPixelImplementation();
      this.loadEvidon();
    }

    this.cdr.detectChanges();
  }

  // Each time the website is redirected, we check the dev key if on dev environment
  watchRoute() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.activeRoute = event.url;
        if (environment.dev) {
          this.checkDevUrl();
          // http://localhost:4200/?s=UniMTM@devTWH
        }
      });
  }

  // Call Amplify API to check if user is logged or not and update the state in the service
  async checkIfUserIsLoggedIn() {
    try {
      const res = await Auth.currentAuthenticatedUser({ bypassCache: true });
      this.accountService.setLoggedIn(true);
    } catch (e) {
      console.log(e);
      this.accountService.setLoggedIn(false);
    }
  }

  // Cancel subscriptions when the page is left for performance purposes
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  // Return the current page from the dictionnary (title, description)
  getActivePage(): MTMPage {
    let activePage: MTMPage;
    Object.values(MTMPages).forEach((page) => {
      if (this.activeRoute.includes(page.route)) {
        activePage = page;
      }
    });
    return activePage;
  }

  // Update the SEO tags
  setSeo() {
    const activePage = this.getActivePage();

    this.seoService.generateTags({
      title: activePage.title,
      description: activePage.description,
      image: activePage.image,
      slug: activePage.route,
    });
  }

  // Update the SEO title
  setTitle() {
    const activePage = this.getActivePage();
    this.title.setTitle(activePage.title);
  }

  private loadEvidonDgScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      let node = document.createElement("script");

      node.src = "https://c.evidon.com/dg/dg.js";
      node.async = false;
      node.setAttribute("companyid", "2523");
      document.getElementsByTagName("head")[0].appendChild(node);

      node.onload = () => resolve();
    });
  }

  private async loadEvidon() {
    await this.loadEvidonDgScript();

    this.dynamicScriptLoader
      .load("evidon")
      .then((data: any) => {
        console.log("Evidon loaded successfully");
      })
      .catch(console.error);
  }

  // Load the Facebook Pixel script
  facebookImplementation() {
    this.dynamicScriptLoader
      .load("facebook-pixel")
      .then((data: any) => {
        console.log("Facebook pixel tracking loaded successfully");
      })
      .catch(console.error);
  }

  // Load the Adobe Tracking script
  async adobeImplementation() {
    try {
      await this.dynamicScriptLoader.load(
        "adobe-tracking",
        "adobe-tracking-min"
      );
      console.log("Adobe tracking loaded successfully");
      // implements body hiding after adobe is loaded sinc eit depends on it
      this.bodyhidingImplementation();
      if (this.locale === "fr") {
        // set digital pageinfo language after adobe tracking has been set
        digitalData.page.pageInfo.language = "FR";
      } else {
        digitalData.page.pageInfo.language = "EN";
      }
    } catch (e) {
      console.error(e);
    }
  }

  // Load the bodyhiding script
  bodyhidingImplementation() {
    this.dynamicScriptLoader
      .load("bodyhiding")
      .then((data: any) => {
        console.log("bodyhiding loaded successfully");
      })
      .catch(console.error);
  }

  // Load the robots tag to prevent indexing if dev environment
  loadRobotsInHeader() {
    const meta = document.createElement("meta");
    meta.name = "robots";
    meta.content = "noindex";
    document.getElementsByTagName("head")[0].appendChild(meta);
  }

  // Load the new relic script
  newRelicImplementation() {
    this.dynamicScriptLoader
      .load("new-relic")
      .then((data: any) => {
        console.log("New Relic loaded successfully");
      })
      .catch(console.error);
  }

  // Load the pixel script
  pixelImplementation() {
    this.dynamicScriptLoader
      .load("pixel-min", "pixel")
      .then((data: any) => {
        console.log("Pixel loaded successfully");
      })
      .catch(console.error);
  }

  // Load the Amazon pixel script
  amazonPixelImplementation() {
    this.dynamicScriptLoader
      .load("loyalty-amazon", "awareness-amazon")
      .then((data: any) => {
        console.log("Amazon loyalty and awareness loaded successfully");
        this.loadAwarenessAmazonPixel = true;
        this.loadLoyaltyAmazonPixel = true;
      })
      .catch(console.error);
  }

  // Load Pinterest script
  pinterestImplementation() {
    this.dynamicScriptLoader
      .load("pinterest")
      .then((data: any) => {
        console.log("Pinterest loaded");
        this.loadPinterest = true;
      })
      .catch(console.error);
  }

  private askToRegister(): void {
    const currentDate: number = new Date().getTime();
    const expiration = this.getCurrentExpiration();

    if (!expiration || expiration < currentDate) {
      this.modalService.open(AskToRegisterModalComponent, {
        maxWidth: 800,
        maxHeight: this.isMobile ? "calc(100vh - 48px)" : 350,
        width: "100%",
        height: "100%",
        panelClass: "ask-to-register",
      });
      this.saveModalState();
    }
  }

  private saveModalState(): void {
    const expiration: number = this.calculateNextsExpiration();
    localStorage.setItem("modalExpiration", expiration.toString());
  }

  private getCurrentExpiration(): number {
    const expiration = localStorage.getItem("modalExpiration");
    return Number.parseInt(expiration);
  }

  private calculateNextsExpiration(): number {
    const currentDate = new Date();
    return this.addMinutes(currentDate, 48).getTime();
  }

  private addMinutes(date: Date, hours: number): Date {
    return new Date(date.getTime() + hours * 3600000);
  }
}
