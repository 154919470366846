<div class="app-container">
        <div class="app-content">
                <router-outlet>
                </router-outlet>

        </div>
        <div class="footer-container no-print">

                <app-footer></app-footer>
        </div>
</div>

<!-- lazy load footer  -->
<!-- <div #footerContainer></div> -->

<!-- amazon img src tag -->
<noscript *ngIf='loadLoyaltyAmazonPixel'>
        <img height='1' width='1' border='0' alt='amazon pixel'
                src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Dc1109f6d-7dbd-0a10-63b3-1ea72ec144ae%26type%3D6%26m%3D7&ex-fch=416613&ex-src=https://www.mealsthatmatter.com/recipes/discover&ex-hargs=v%3D1.0%3Bc%3D2841838420101%3Bp%3DC1109F6D-7DBD-0A10-63B3-1EA72EC144AE' />
</noscript>
<noscript *ngIf='loadAwarenessAmazonPixel'> <img height='1' width='1' border='0' alt='amazon awareness pixel'
                src='https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D524862a4-bae3-a0d1-3979-ff8aef12c79e%26type%3D55%26m%3D7&ex-fch=416613&ex-src=https://www.mealsthatmatter.com/&ex-hargs=v%3D1.0%3Bc%3D2841838420101%3Bp%3D524862A4-BAE3-A0D1-3979-FF8AEF12C79E' /></noscript>
<noscript *ngIf='loadPinterest'>
        <!-- pinterest -->
        <img height="1" width="1" style="display:none;" alt="pinterest pixel"
                src="https://ct.pinterest.com/v3/?event=init&tid=2613993939117&pd[em]=<hashed_email_address>&noscript=1" />
</noscript>

<!-- end load files -->