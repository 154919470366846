<footer class="app-footer">
  <ng-container *ngIf="isMobile">
    <div class="visit-brands">
      <a
        rel="noopener"
        class="titlecase"
        matTooltip="Opens in a new tab"
        (click)="
          adobeDtbTracking.footerLinks(
            'visit knorr',
            isFrench
              ? 'https://www.knorr.com/ca/fr/home.html'
              : 'https://www.knorr.com/ca/en/home.html',
            'external'
          )
        "
        target="_blank"
        [href]="
          isFrench
            ? 'https://www.knorr.com/ca/fr/home.html'
            : 'https://www.knorr.com/ca/en/home.html'
        "
        i18n
      >
        visit knorr
      </a>
      <a
        rel="noopener"
        class="titlecase"
        matTooltip="Opens in a new tab"
        (click)="
          adobeDtbTracking.footerLinks(
            'visit hellmanns',
            isFrench
              ? 'https://www.hellmanns.com/ca/fr/home.html'
              : 'https://www.hellmanns.com/ca/en/home.html',
            'external'
          )
        "
        target="_blank"
        [href]="
          isFrench
            ? 'https://www.hellmanns.com/ca/fr/home.html'
            : 'https://www.hellmanns.com/ca/en/home.html'
        "
        href=""
        i18n
      >
        visit hellmann's
      </a>
    </div>
    <div class="social-icons">
      <a
        matTooltip="Opens in a new tab"
        rel="noopener"
        *ngFor="let item of socialFooterItems"
        (click)="adobeDtbTracking.footerLinks(item.name, item.href, 'social')"
        target="_blank"
        [href]="item.href"
      >
        <img
          loading="lazy"
          [class]="item.name !== 'pinterest' ? 'collapse' : ''"
          [src]="item.img"
          [alt]="item.name"
        />
      </a>
    </div>
    <div class="ad-logos">
      <span class="evidon-notice-link"></span>
      <a
        class="unilever-logo"
        [href]="
          isFrench ? 'https://www.unilever.ca/fr/' : 'https://www.unilever.ca/'
        "
        matTooltip="Opens in a new tab"
        (click)="
          adobeDtbTracking.footerLinks(
            'Unilever',
            isFrench
              ? 'https://www.unilever.ca/fr/'
              : 'https://www.unilever.ca/',
            'external'
          )
        "
      >
        <img
          loading="lazy"
          class="unilever-logo"
          src="/assets/static_images/footer/unilever-logo.svg"
          alt="Unilever logo"
        />
        <span i18n>© 2022 Unilever</span>
      </a>
    </div>
  </ng-container>
  <div *ngIf="!isMobile" class="desktop-wrapper">
    <a
      rel="noopener"
      class="titlecase"
      matTooltip="Opens in a new tab"
      (click)="
        adobeDtbTracking.footerLinks(
          'visit knorr',
          isFrench
            ? 'https://www.knorr.com/ca/fr/home.html'
            : 'https://www.knorr.com/ca/en/home.html',
          'external'
        )
      "
      target="_blank"
      [href]="
        isFrench
          ? 'https://www.knorr.com/ca/fr/home.html'
          : 'https://www.knorr.com/ca/en/home.html'
      "
      i18n
    >
      visit knorr
    </a>
    <a
      rel="noopener"
      class="titlecase"
      matTooltip="Opens in a new tab"
      (click)="
        adobeDtbTracking.footerLinks(
          'visit hellmanns',
          isFrench
            ? 'https://www.hellmanns.com/ca/fr/home.html'
            : 'https://www.hellmanns.com/ca/en/home.html',
          'external'
        )
      "
      target="_blank"
      [href]="
        isFrench
          ? 'https://www.hellmanns.com/ca/fr/home.html'
          : 'https://www.hellmanns.com/ca/en/home.html'
      "
      i18n
    >
      visit hellmann's
    </a>

    <a
      rel="noopener"
      *ngFor="let item of socialFooterItems"
      matTooltip="Opens in a new tab"
      (click)="adobeDtbTracking.footerLinks(item.name, item.href, 'social')"
      target="_blank"
      [href]="item.href"
    >
      <img
        loading="lazy"
        [class]="item.name !== 'pinterest' ? 'collapse' : ''"
        [src]="item.img"
        [alt]="item.name"
      />
    </a>
    <span class="evidon-notice-link"></span>
    <a
      class="unilever-logo"
      [href]="
        isFrench ? 'https://www.unilever.ca/fr/' : 'https://www.unilever.ca'
      "
      matTooltip="Opens in a new tab"
      (click)="
        adobeDtbTracking.footerLinks(
          'Unilever',
          isFrench ? 'https://www.unilever.ca/fr/' : 'https://www.unilever.ca',
          'external'
        )
      "
    >
      <img
        class="unilever-logo"
        src="/assets/static_images/footer/unilever-logo.svg"
        alt="Unilever logo"
      />
      <span i18n>© 2022 Unilever</span>
    </a>
  </div>
  <div class="legal">
    <a
      rel="noopener"
      [href]="
        isFrench
          ? 'https://www.unilever.ca/fr/legal.html'
          : 'https://www.unilever.ca/legal.html'
      "
      target="_blank"
      matTooltip="Opens in a new tab"
      (click)="
        adobeDtbTracking.footerLinks(
          'terms of use',
          isFrench
            ? 'https://www.unilever.ca/fr/legal.html'
            : 'https://www.unilever.ca/legal.html',
          'external'
        )
      "
      i18n
      class="titlecase"
      >terms of use</a
    >
    <a
      rel="noopener"
      [href]="
        isFrench
          ? 'https://www.unilevernotices.com/canada/french/privacy-notice/notice.html'
          : 'https://www.unilevernotices.com/canada/english/privacy-notice/notice.html'
      "
      target="_blank"
      matTooltip="Opens in a new tab"
      i18n
      (click)="
        adobeDtbTracking.footerLinks(
          'privacy policy',
          isFrench
            ? 'https://www.unilevernotices.com/canada/french/privacy-notice/notice.html'
            : 'https://www.unilevernotices.com/canada/english/privacy-notice/notice.html',
          'external'
        )
      "
      i18n
      class="titlecase"
      title="privacy notice"
      >privacy notice</a
    >
    <a
      routerLink="/faqs"
      queryParamsHandling="preserve"
      class="uppercase"
      (click)="adobeDtbTracking.pageTracking('faq', '/faqs')"
      i18n
      >faq</a
    >
  </div>
  <div class="accessibility">
    <a
      rel="noopener"
      class="titlecase"
      (click)="adobeDtbTracking.pageTracking('sitemap', '/sitemap')"
      routerLink="/sitemap"
      queryParamsHandling="preserve"
      i18n
    >
      sitemap
    </a>
    <a
      matTooltip="Opens in a new tab"
      target="_blank"
      rel="noopener"
      class="titlecase"
      (click)="adobeDtbTracking.pageTracking('contact-us', '/contact-us')"
      [href]="
        isFrench
          ? 'https://www.chaquerepascompte.com/contact-us'
          : 'https://www.mealsthatmatter.com/contact-us'
      "
      queryParamsHandling="preserve"
      i18n
    >
      contact us
    </a>
  </div>

  <div class="footer-wrapper">
    <p class="footer-text" i18n>
      This website is intended for Canadian consumers of products and services
      of Unilever Canada Inc. This website is not intended for consumers outside
      Canada.
    </p>
  </div>
</footer>
