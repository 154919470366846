// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { environment } from "./environments/environment";
let awsmobile = {};
if (environment.production) {
  awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_lVqDm9q68",
    aws_user_pools_web_client_id: "1ld51mfch9e1qi946qhn7eg8dr",
    oauth: {
      domain: "mtm.auth.us-east-1.amazoncognito.com",
      scope: ["openid", "aws.cognito.signin.user.admin", "email", "profile"],
      redirectSignIn: environment.baseUrl + "",
      redirectSignOut: environment.baseUrl + "/logout",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
  };
} else {
  //Cognito UAT (mealsthatmatter-uat) => used for dev and UAT
  awsmobile = {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_XoL2HeK8Y",
    aws_user_pools_web_client_id: "69sjfo3b8oq82hapcmge34ff6e",
    oauth: {
      domain: "mtm-uat.auth.us-east-1.amazoncognito.com",
      scope: ["openid", "aws.cognito.signin.user.admin", "email", "profile"],
      redirectSignIn: environment.baseUrl + "",
      redirectSignOut: environment.baseUrl + "/logout",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
  };
}

export default awsmobile;
