<mat-dialog-content class="d-flex">
  <a mat-dialog-close class="main-close-button"
    ><img src="https://cimage.adobe.com/unilever/EMEA/images.jpg"
  /></a>
  <div class="left-column"></div>

  <div class="right-column">
    <div class="right-content">
      <h2 i18n>Let's Get Cooking!</h2>
      <p i18n>
        Make meal planning easier than ever with Meals That Matter recipes,
        cooking tips, and exclusive offers delivered right to your inbox.
      </p>

      <button
        type="button"
        disableRipple
        mat-button
        class="sign-up-button"
        (click)="signUp()"
        i18n
      >
        Sign Up
      </button>

      <button
        type="button"
        disableRipple
        mat-button
        class="close-button"
        mat-dialog-close
        i18n
      >
        No, thanks.
      </button>
    </div>
  </div>
</mat-dialog-content>
